import React, { useEffect, useState } from 'react';
import { Container, Col, Row, Button } from 'react-bootstrap';
import { Form, InputNumber, message, Input } from 'antd';
import Navigation from './Navigation';
import Footer from './Footer';
import './Content.css';
import { gql, useLazyQuery } from '@apollo/client';
import { patterns } from '../Constants';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Home = () => {

    const [form] = Form.useForm();
    const [status, setStatus] = useState('');
    const [date, setStatusDate] = useState();
    const [getParam, setParam] = useSearchParams()
    const [shippedmessage, showhideshippedmessage] = useState(false);
    const [notshippedmessage, showhidenotshippedmessage] = useState(false);
    const [notFoundPayment, showhidenotFound] = useState(false);
    const [declinedPayment, showhideDeclinedMessage] = useState(false);
    const [declinedReason, setDeclineReason] = useState(false);
    const [voidedmessage, showhidevoidedmessage] = useState(false);
    const [processed, setProcessed] = useState(false);  
    const { t } = useTranslation();


    const GET_STATUS = gql`
    query getStatusByTrackingNumber($trackingNumber:Float!){
        getStatusByTrackingNumber(trackingNumber: $trackingNumber) {
          trackedStatus
        }
      }`

    useEffect(() => {
        if (getParam.get('trackingNumber')) {
            form.setFieldsValue({
                trackingNumber: getParam.get('trackingNumber')
            })
            getStatus({ variables: { trackingNumber: +getParam.get('trackingNumber') } })
        }
    }, [])

    const [getStatus] = useLazyQuery(GET_STATUS, {
        onCompleted(status) {
            setStatusDate("")
            if (status.getStatusByTrackingNumber.trackedStatus.includes('|')) {

                let errMes = status.getStatusByTrackingNumber.trackedStatus.split('|')

                if (errMes[0] === 'shipped_tracking') {
                    setStatus(errMes[0])
                    setStatusDate(errMes[1])
                    showhideshippedmessage(true);
                }
                else if (status.getStatusByTrackingNumber.trackedStatus.includes('not_shipped_tracking')) {
                    setStatus(errMes[0])
                    setStatusDate(errMes[1])
                    showhidenotshippedmessage(true);
                }
                setStatus(errMes[0])
                setStatusDate(errMes[1])
            }

            else {

                if (status.getStatusByTrackingNumber.trackedStatus.includes('not_funded_tracking')) {
                    setStatus(status.getStatusByTrackingNumber.trackedStatus)
                }
                else if (status.getStatusByTrackingNumber.trackedStatus.includes('not_redeemed_tracking')) {
                    setStatus(status.getStatusByTrackingNumber.trackedStatus)
                }
                else if (status.getStatusByTrackingNumber.trackedStatus.includes('processed')) {
                    setStatus(status.getStatusByTrackingNumber.trackedStatus);
                    setProcessed(true);
                }
                else {
                    setStatus(status.getStatusByTrackingNumber.trackedStatus)
                }

            }
        },
        onError(err) {
            setStatusDate("")
            if (err.message.includes('|')) {
                let errMes = err.message.split('|')
                if (err.message.includes("payment_not_found_tracking")) {
                    setStatus(errMes[0]);
                    showhidenotFound(true);
                    setStatusDate(errMes[1]);
                }
                else if (err.message.includes('declined_tracking')) {
                    setStatus(errMes[0]);
                    setDeclineReason(errMes[1]);
                    setStatusDate(errMes[2]);
                    showhideDeclinedMessage(true);
                }
                else if (err.message.includes('voided_tracking')) {
                    setStatus(errMes[0]);
                    setStatusDate(errMes[1]);
                    showhidevoidedmessage(true);
                }
            } else {
                console.log("error message")
                setStatus(err.message)
            }
        },
        fetchPolicy: 'network-only'
    })


    const handleFormSubmit = () => {
        showhidenotFound();
        showhidenotshippedmessage();
        showhideshippedmessage();
        showhideDeclinedMessage();
        showhidevoidedmessage();
        let tracking = form.getFieldValue().trackingNumber
        getStatus({ variables: { trackingNumber: +tracking } })
    }


    return (
        <section>
            <Navigation />
            <section className='vh-fill'>
                <Container className='header'>
                    <Row className='justify-content-center py-5'>
                        <Col md={6} className='header-box text-center p-5'>
                            <h2 className='text-white'>{t('track_payment_now')}</h2>
                            <h6 className='text-white'>{t('enter_tracking_no')}</h6>
                        </Col>
                    </Row>
                </Container>
                <Container className='my-5 py-4 border'>
                    <Row>
                        <Col md={6} className='px-4'>
                            <h6 className='border-bottom pb-2 mb-3'>{t('tracking_number_header')}</h6>
                            <Form form={form} layout="vertical" name="dynamic_rule" onFinish={handleFormSubmit}>
                                <Form.Item className="mb-3" name={'trackingNumber'}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('enter_valid_tracking_number')
                                        },
                                        {
                                            pattern: patterns.phoneNumberCheckPattern,
                                            message: t('value_contain_number')
                                        }
                                    ]}>

                                    <Input className='w-100' placeholder={t('enter_tracking_number')} />
                                </Form.Item>
                                <Button type="submit" className='powur-btn-primary btn-block w-100'>
                                    {t('Track_Now')}
                                </Button>
                            </Form>
                        </Col>
                        <Col md={6} className='px-4 pt-4 pt-md-0'>
                            <h6 className='border-bottom pb-2 mb-3'>{t('current_status')}</h6>
                            {
                                shippedmessage ?
                                    <>
                                        <div>{t(`${status}`)} {date}</div>
                                    </> :
                                    ''
                            }
                            {
                                processed ?
                                <>
                                    <div>{t(`${status}`)} <a href='/contactus' className='powur-link'>{t('Contact_Us')}</a></div>
                                </> : ''
                            }
                            {
                                notshippedmessage ?
                                    <>
                                        <div>{t(`${status}`)}</div>
                                        <div>{t('not_shipped_tracking_approved_date')} {date}</div>
                                    </> :
                                    ''
                            }
                            {
                                notFoundPayment ?
                                    <>
                                        <div>{t(`${status}`)} : {date}</div>
                                    </> : ''

                            }
                            {
                                declinedPayment ?
                                    <>
                                        <div>{t(`${status}`)}</div>
                                        <div>{t('declined_reason_tracking')} {t(`${declinedReason}`)}</div>
                                        <div>{t('declined_date_tracking')} {date}</div>
                                    </> :
                                    ''
                            }
                            {
                                voidedmessage ?
                                    <>
                                        <div>{t(`${status}`)}{" "}<a href='/contactus'>{t('voided_tracking_contactus')}</a>{" "}{t(`voided_tracking_part`)}</div>
                                        <div>{t('date_voided')} {date}</div>
                                    </> :
                                    ''
                            }
                            {(processed === true || shippedmessage === true || notshippedmessage === true || notFoundPayment === true || declinedPayment === true || voidedmessage === true) ?
                                ''
                                :
                                <>
                                    <div>{t(`${status}`)}</div>
                                    <div>{date}</div>
                                </>

                            }

                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </section>
    );
}

export default Home;