import React, { useEffect,useState } from 'react'
import {Fragment} from 'react';
import 'antd/dist/antd.min.css';
import { Container, Col, Row, Accordion } from 'react-bootstrap';
import Navigation from './Navigation';
import Footer from './Footer';
import './Content.css';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { gql, useQuery, useMutation , useLazyQuery} from '@apollo/client';

const getEmailInSpanish = gql`
query Query($token: String!) {
    getEmailInSpanish(token: $token)
}`;
    
    
    
export default function SpanishEmail() {
    const [token, setToken] = useState();
    const [message, setMessage] = useState("Please Wait");
    const [errorMessage, setErrorMessage] = useState();
    const [getParam, setParam] = useSearchParams();
    const {t}= useTranslation();

    const getSpanishEmailInfo = useQuery(getEmailInSpanish, {
        variables: {
            token: token
        },
        skip: token === undefined
    });
       
    const error_message= (
       <>
        <p>Token expired or invalid token please<a href="/contactus"> Contact us </a></p>
       </>
    )

    useEffect(() => {
      setToken(getParam.get("token"));
      if(!getSpanishEmailInfo.loading && getSpanishEmailInfo.data?.getEmailInSpanish){
          setMessage("spanish_translation_for_email_requested");
      }
      if (getSpanishEmailInfo.error) {
        setErrorMessage(error_message);
      }
    }, [token,getSpanishEmailInfo,message]);
    
    return (
        <>   
          <section >
              <Navigation />
              <Container className='my-5 min-vh-100'>
                  <Row className="text-center">
                        <p>
                             {(message==="spanish_translation_for_email_requested")? t(message):errorMessage}
                        </p>
                  </Row>
              </Container>
          </section>
          <Footer />
          </>
    );
}
