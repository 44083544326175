import React, {Fragment} from 'react';
import 'antd/dist/antd.min.css';
import { Container, Col, Row, Accordion } from 'react-bootstrap';
import Navigation from './Navigation';
import TrackingHeader from './TrackingHeader';
import Footer from './Footer';
import './Content.css';
import { useTranslation } from 'react-i18next';


const FAQ = () => {

    const {t}= useTranslation();

    return (
        <section>
            <Navigation />
            <TrackingHeader />
            <Container className='my-5'>
                <Row>
                    <Col>
                        <h2>{t('Frequently_Asked_Questions')}</h2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Accordion defaultActiveKey={['0']} alwaysOpen flush>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>{t('faq1')}</Accordion.Header>
                                <Accordion.Body>{t('ans1')} <a href='/contactus' className='powur-link'>{t('contact_us_hyperlink')}</a>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>{t('faq2')}</Accordion.Header>
                                <Accordion.Body>{t('ans2')}
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>{t('faq3')}</Accordion.Header>
                                <Accordion.Body>{t('ans3')} <a href='/contactus' className='powur-link'>{t('contact_us_hyperlink3')}</a>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>{t('faq4')}</Accordion.Header>
                                <Accordion.Body>{t('ans4')} <a href='/contactus' className='powur-link'>{t('contact_us_hyperlink4')}</a>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>{t('faq5')}</Accordion.Header>
                                <Accordion.Body>{t('ans5')} <a href='/contactus' className='powur-link'>{t('contact_us_hyperlink5')}</a>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>{t('faq6')}</Accordion.Header>
                                <Accordion.Body>{t('ans6')} <a href='/contactus' className='powur-link'>{t('contact_us_hyperlink6')}</a>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="6">
                                <Accordion.Header>{t('faq7')}</Accordion.Header>
                                <Accordion.Body>{t('ans7')}</Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="7">
                                <Accordion.Header>{t('faq8')}</Accordion.Header>
                                <Accordion.Body>{t('ans8')} <a href='/contactus' className='powur-link'>{t('contact_us_hyperlink8')}</a>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="8">
                                <Accordion.Header>{t('faq9')}</Accordion.Header>
                                <Accordion.Body>{t('ans91')}</Accordion.Body>
                                <Accordion.Body>{t('ans92')}</Accordion.Body>
                                <Accordion.Body>{t('ans93')}</Accordion.Body>
                                <Accordion.Body>{t('ans94')}</Accordion.Body>
                                <Accordion.Body>{t('ans95')}</Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </section>
    );
}

export default FAQ;