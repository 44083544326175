import React, { useState, useEffect } from 'react';
import { Container, Image, Row, Col, Button } from 'react-bootstrap';
import { gql, useQuery, useMutation } from '@apollo/client'
import { message, Radio } from 'antd';
import Footer from './Footer';
import './Content.css';
import { Form, Input, Select } from 'antd';
import { useTranslation } from "react-i18next";
import i18next from 'i18next';
import { Link, useLocation } from 'react-router-dom';
import TrackingHeader from './TrackingHeader';







const PaymentsMessage = (state) => {


    const { t } = useTranslation();
    const { Option } = Select;
    const location = useLocation();

    return (
        <>
            <Container fluid className='p-4'>
                <Row>
                    <Col md={10}>
                        <Image fluid src='images/Power Logo.png' className='nav-logo' />
                    </Col>
                </Row>
            </Container>
            <Container>

                <Row>
                    <Col>

                        <TrackingHeader trackingNumber={location.state.trackingNumber}/>

                    </Col>
                </Row>
                <Row className='mt-5'>
                    <Col className='text-center'>

                        <h3>{location.state.message}</h3>

                    </Col>
                </Row>
                <Row className='my-5'>
                </Row>
                <Footer />
            </Container>
        </>
    );
}

export default PaymentsMessage;