import React from "react";
import { Fragment } from "react";
import "antd/dist/antd.min.css";
import { Container, Col, Row } from "react-bootstrap";
import Navigation from "./Navigation";
import Footer from "./Footer";
import "./Content.css";
import { useTranslation } from "react-i18next";
export default function TokenExpired() {
  return (
    <>
      <section>
        <Navigation />
        <Container className="my-5 min-vh-100">
          <Row className="text-center">
            <>
              <p>
                Token Expired or Invalid Token please
                <a href="/contactus"> Contact us </a>
              </p>
            </>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
}
