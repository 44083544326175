import React, { Fragment, useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import { Container, Col, Row, Form, Button, InputGroup } from 'react-bootstrap';
import { Input } from 'antd';
import './Content.css';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';


const TrackingHeader = (props) => {

    const { t } = useTranslation();
    const [trackingNumber, setTrackingNumber] = useState();

    const redirect = useNavigate();


    useEffect(() => {
        if (props.trackingNumber) {
            setTrackingNumber(props.trackingNumber)
        }
    },[props.trackingNumber])

    return (
        <section>
            <Container className='header'>
                <Row className='justify-content-center py-5'>
                    <Col md={6} className='header-box text-center p-5'>
                        <h2 className='text-white'>{t("Track_Payment_Now")}</h2>
                        <h6 className='text-white pb-2'>{t('Enter_tracking_number_text')}</h6>
                        <Row>
                            <Col className='' md={12} >
                                <Input className='w-75 h-100' value={props.trackingNumber} onChange={(val) => { setTrackingNumber(val.target.value) }}
                                    placeholder={t('Enter_Tracking_Number_placeholder')}
                                    aria-label="Tracking Number"
                                />
                                <Button className='powur-btn-primary trackNowBtn w-25' onClick={() => {
                                    redirect({
                                        pathname: '/',
                                        search: `?trackingNumber=${trackingNumber}`
                                    })
                                }}>
                                    {t('Track_Now')}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default TrackingHeader;