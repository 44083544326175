import React, { useState, useEffect } from 'react';
import { Container, Image, Row, Col, Button } from 'react-bootstrap';
import { gql, useQuery, useMutation,useLazyQuery } from '@apollo/client'
import { message, Radio, Spin } from 'antd';
import Footer from './Footer';
import './Content.css';
import { Form, Input, Select,AutoComplete } from 'antd';
import { useTranslation } from "react-i18next";
import i18next from 'i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { cities, patterns, rewardTypes } from '../Constants';
import ErrorList from 'antd/lib/form/ErrorList';
import { amountFormat } from '../Helper/helperFunction';






const PaymentsForm = () => {

    const [form] = Form.useForm();
    const { t } = useTranslation();
    const redirect = useNavigate();
    const { Option } = Select;
    const [selected, setSelected] = useState(localStorage.getItem('i18nextLng') || 'en')
    const [token, setToken] = useState();
    const [virtualVisaDisable, setvirtualVisaDisable] = useState(false);
    const [check, setCheck] = useState(false);
    const [disable, setDisable] = useState(false);
    const [phydisable, setPhyDisable] = useState(false);
    const [hidden, setHidden] = useState(true);
    const [getParam, setParam] = useSearchParams()
    const [paymentReemed, setpaymentReemed] = useState(false);
    const [options, setoptions] = useState(false);
    const [addressValidated, setAddressValidation] = useState();
    const [secondaryAddressValidated, setSecondaryAddressValidation] = useState(false);
    
    
    useEffect(()=>{
      if(!addressValidated){
        form.validateFields(["mailingAddress1","mailingCity", "mailingState", "mailingZip"]);      
      }
      else{
        form.validateFields(["mailingAddress2"]);      
      }
    },[secondaryAddressValidated,addressValidated])

    const checkPaymentType = (amount) => {        
        if (amount > 0 && amount <= 2500) {
          if (amount < 500) {
            setCheck(true);
          } 
          else if (amount >= 500) {
            setCheck(false);
          } 
          else if ( amount > 1000) {
            setvirtualVisaDisable(true);
          }
        } 
        else if ( amount > 2500) {
          setPhyDisable(true);
          setvirtualVisaDisable(true);
        }
    }

    const GET_PAYMENTFORM_DATA = gql`
    query getOrderDetailsByToken($token : String!){
        getOrderDetailsByToken(token: $token) {
          paymentType
          projectNumber
          paymentAmount
          attentionTo
          customerEmail
          customerFirstName
          customerLastName
          installationAddress1
          installationAddress2
          installationCity
          installationState
          installationZip
          mailingAddress1
          mailingAddress2
          mailingCity
          mailingState
          mailingZip
          phone
          trackingNumber
          memo
          status
          reason
          approvedAtTime
          id
          createdAt
          updatedAt
          rewardType
          approvedByUser
          redeemedAt
          shippedDate
          reissueDate
          addressChangeReason
          paymentAction
          autoRedeemed
          redeemed
        }
      } `

    const UPDATE_PAYMENT = gql`
    mutation updateIndividualPayment($email:String!, $paymentType:String!, $token:String!, $languageType:String!
        $mailingAddress1:String!, $mailingAddress2:String, $mailingState:String!, $mailingCity:String!, $mailingZip:String!) {
        updateIndividualPayment(
          email: $email
          paymentType: $paymentType
          token: $token
          mailingAddress1: $mailingAddress1
          mailingAddress2: $mailingAddress2
          mailingState: $mailingState
          mailingCity: $mailingCity
          mailingZip: $mailingZip
          languageType: $languageType

        ) {
          message
        }
      }`

      const AUTOCOMPLETEADDRESS=gql` 
      mutation AutocompleteAddress($address:String!){
        autoCompleteAddress(address: $address) {
          streetLine
          secondary
          city
          state
          zipcode
          entries
        }
      }`
      
      const VALIDATEADDRESS=gql`
      mutation ValidateAddress($mailingAddress1: String!, $mailingCity: String!, $mailingState: String!, $mailingZip: String!, $mailingAddress2: String) {
        validateAddress(mailingAddress1: $mailingAddress1, mailingCity: $mailingCity, mailingState: $mailingState, mailingZip: $mailingZip, mailingAddress2: $mailingAddress2) {
          message
        }
      }`;

      const[getAutoCompleteAddress]=useMutation(AUTOCOMPLETEADDRESS);
      const[getAddressValidation]=useMutation(VALIDATEADDRESS);

    const getPaymentData = useQuery(GET_PAYMENTFORM_DATA, {
        variables: {
            token: token
        }
    });

    const [updateIndividualPayment] = useMutation(UPDATE_PAYMENT)

    useEffect(() => {
        setToken(getParam.get('token'))
        if(getPaymentData.error){
            if ((!getPaymentData.loading ) && ( getPaymentData.error?.message == "jwt expired" || getPaymentData.error?.message == "invalid token" )){
                 redirect('/token-expired');
            }
            if ((!getPaymentData.loading ) && (getPaymentData.error?.message == "Payment already redeemed!")){
                    redirect('/payment-status', { state: { message: t('payment_status')} });
            }
            if((!getPaymentData.loading ) && (getPaymentData.error?.message == "Your payment has been voided. Please contact Powur Customer Service")){
              redirect('/payment-status', { state: { message: t('voided_tracking')} });
            }
        }
        if (!getPaymentData.loading) {
            if (getPaymentData.data == undefined) {
                form.setFieldsValue({
                    trackingNumber: '',
                    paymentAmount: '',
                    customerFirstName: '',
                    customerLastName: '',
                    customerEmail: '',
                    phone: '',
                    mailingAddress1: '',
                    mailingAddress2: '',
                    mailingCity: '',
                    mailingState: '',
                    mailingZip: '',
                    paymentType: ''
                })
                setpaymentReemed(true)
                setDisable(true)


            } else {
              const formatedAmount = amountFormat(getPaymentData.data.getOrderDetailsByToken.paymentAmount);
                form.setFieldsValue({
                    trackingNumber: getPaymentData.data.getOrderDetailsByToken.trackingNumber,
                    paymentAmount: formatedAmount,
                    customerFirstName: getPaymentData.data.getOrderDetailsByToken.customerFirstName,
                    customerLastName: getPaymentData.data.getOrderDetailsByToken.customerLastName,
                    customerEmail: getPaymentData.data.getOrderDetailsByToken.customerEmail,
                    phone: getPaymentData.data.getOrderDetailsByToken.phone,
                    mailingAddress1: getPaymentData.data.getOrderDetailsByToken.mailingAddress1,
                    mailingAddress2: getPaymentData.data.getOrderDetailsByToken.mailingAddress2,
                    mailingCity: getPaymentData.data.getOrderDetailsByToken.mailingCity,
                    mailingState: getPaymentData.data.getOrderDetailsByToken.mailingState,
                    mailingZip: getPaymentData.data.getOrderDetailsByToken.mailingZip,
                    paymentType: getPaymentData.data.getOrderDetailsByToken.rewardType
                })
                checkPaymentType(getPaymentData.data.getOrderDetailsByToken.paymentAmount)
            }

        }
    }, [getPaymentData, token])


    const paymentFormHandler = async () => {
        const { trackingNumber, paymentAmount, paymentType, customerFirstName, customerLastName, customerEmail, phone, mailingAddress1, mailingAddress2, mailingCity, mailingState, mailingZip } = form.getFieldValue();
        try {
            const res = await updateIndividualPayment({
                variables: {
                    email: customerEmail,
                    paymentType: paymentType,
                    token: token,
                    mailingAddress1: mailingAddress1,
                    mailingAddress2: mailingAddress2,
                    mailingState: mailingState,
                    mailingCity: mailingCity,
                    mailingZip: mailingZip,
                    languageType: selected
                }
            })
            if (res) {
                redirect('/payment-status', { state: { message: t('Payment_successfully_submitted'),  trackingNumber:trackingNumber} });

            }
        } catch (error) {
            message.error({
                content: error?.message,
                style: {
                    marginTop: "10vh",
                    fontSize: "1vw",
                },
                duration: 10,
            });
        }
    }

    const handleChange = (value) => {
        localStorage.setItem('i18nextLng', value)
        setSelected(value)
        i18next.changeLanguage(value)
    };

    let cleartime;
    const debounce = (lazyQuery) => {
      clearTimeout(cleartime)
      cleartime = setTimeout(() => {
        lazyQuery()
      }, 1000)
    }

    const validateaddress= async ()=>{
      setSecondaryAddressValidation(false);
      setAddressValidation(true);
      const { mailingAddress1,mailingAddress2, mailingCity, mailingState, mailingZip, paymentType } = form.getFieldValue()
      const data= await getAddressValidation({
        variables:{
          mailingAddress1: mailingAddress1,
          mailingAddress2: mailingAddress2,
          mailingCity: mailingCity,
          mailingState: mailingState,
          mailingZip: mailingZip,
        }
      })
      
      if(data.data.validateAddress.message==="true"){
        setAddressValidation(true);       
      }
      else if(data.data.validateAddress.message==="D"){
        setSecondaryAddressValidation(true);
        form.validateFields(["mailingAddress2"]);
      }
      else if(data.data.validateAddress.message==="false"){
       setAddressValidation(false);
      }
    }
    const verifyHandler = () => {
      validateaddress();
      const { mailingAddress1,mailingAddress2, mailingCity, mailingState, mailingZip, paymentType } = form.getFieldValue()
        if (mailingAddress1 === "" || mailingState === "" || mailingCity === "" ||
            mailingZip === "", paymentType === "") {
            form.validateFields();
            form.setFields();
        } else {
            setDisable(true);
            setHidden(false);
        }
    }

    const handleSearch = async (value) => {
      setAddressValidation(true);
      let data = await getAutoCompleteAddress({
        variables: { address: value },
      });
      let res = data.data.autoCompleteAddress.map((record) => ({
        value: `${record.streetLine},${record.secondary},${record.city},${record.state},${record.zipcode}`,
        label: `${record.streetLine},${record.secondary},${record.city},${record.state},${record.zipcode}`,
      }));

      setoptions(res);
    };

    const onSelect = async (value, option) => {
      const addressParts = value.split(",");
      form.setFieldsValue({
        mailingAddress1: addressParts[0],
      });
      form.setFieldsValue({
        mailingAddress2: addressParts[1],
      });
      form.setFieldsValue({
        mailingCity: addressParts[2],
      });
      form.setFieldsValue({
        mailingState: addressParts[3],
      });
      form.setFieldsValue({
        mailingZip: addressParts[4],
      });
    };

    return (
      <>
        <Container fluid className="p-4">
          <Row>
            <Col md={10}>
              <Image fluid src="images/Power Logo.png" className="nav-logo" />
            </Col>
            <Col md={2}>
              <Row>
                <Col>
                  <Select
                    className="w-50"
                    onChange={handleChange}
                    defaultValue={selected}
                  >
                    <Option value="en">English</Option>
                    <Option value="es">Spanish</Option>
                  </Select>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="bg-powur-blue p-2">
            <Col>
              <h2 className="text-white">{t("powur_payment_form")}</h2>
            </Col>
          </Row>
          <Row className="py-2">
            <Col>
              <p>{t("payment_Form_heading")}</p>
            </Col>
          </Row>
          <Row>
            <Col></Col>
          </Row>
          <Row>
            <Col>
              {getPaymentData.loading ? (
                <Spin
                  className=" spin d-flex align-items-center justify-content-center"
                  size="large"
                ></Spin>
              ) : (
                <Form
                  form={form}
                  layout="vertical"
                  name="dynamic_rule"
                  onFinish={paymentFormHandler}
                >
                  <h5>{t("Account_Information")}</h5>
                  <Row className="mb-3 border-bottom pb-2">
                    <Col>
                      <Form.Item
                        as={Col}
                        className="mb-3"
                        label={t("customer_number")}
                        name={"trackingNumber"}
                      >
                        <Input disabled />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        as={Col}
                        className="mb-3"
                        label={t("payment_Amount")}
                        name={"paymentAmount"}
                      >
                        <Input disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className="mb-3 border-bottom pb-2">
                    <Col>
                      <h5>{t("Homeowner_Information")}</h5>
                      <Row className="mb-2">
                        <Col>
                          <Form.Item
                            as={Col}
                            className="mb-3"
                            label={t("first_name")}
                            name={"customerFirstName"}
                          >
                            <Input disabled />
                          </Form.Item>
                        </Col>
                        <Col>
                          <Form.Item
                            as={Col}
                            className="mb-3"
                            label={t("last_name")}
                            name={"customerLastName"}
                          >
                            <Input disabled />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col>
                          <Form.Item
                            as={Col}
                            className="mb-3"
                            label={t("email")}
                            name={"customerEmail"}
                          >
                            <Input disabled />
                          </Form.Item>
                        </Col>
                        <Col>
                          <Form.Item
                            as={Col}
                            className="mb-3"
                            label={t("phone")}
                            name={"phone"}
                          >
                            <Input disabled />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="my-4 border-bottom pb-2">
                    <Col>
                      <h5>{t("Mailing_Address")}</h5>
                      <Row className="mb-2">
                        <Col>
                          <Form.Item
                            as={Col}
                            className="mb-3"
                            label={t("address1")}
                            name={"mailingAddress1"}
                            rules={[
                              {
                                required: true,
                                message: t("Address1_validation_message"),
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (!addressValidated) {
                                    return Promise.reject(t("add1_error"));                                  }
                                  return Promise.resolve();
                                },
                              }),
                            ]}
                          >
                             <AutoComplete
                              // onSearch={handleSearch}
                              onKeyDown={(e) => {
                                debounce(() => handleSearch(e.target.value));
                              }}
                              onSelect={onSelect}
                              options={options}
                              disabled={disable}
                            />
                            
                          </Form.Item>
                        </Col>
                        <Col>
                          <Form.Item
                            as={Col}
                            className="mb-3"
                            label={t("address2")}
                            name={"mailingAddress2"}
                            rules={[
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (secondaryAddressValidated) {
                                    return Promise.reject(t("add2_error"));
                                  }
                                  return Promise.resolve();
                                },
                              }),
                            ]}
                          >
                            <Input disabled={disable} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Item
                            as={Col}
                            className="mb-3"
                            label={t("city")}
                            name={"mailingCity"}
                            rules={[
                              {
                                required: true,
                                message: t("City_validation_message"),
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (!addressValidated) {
                                    return Promise.reject(t("city_error"));
                                  }
                                  return Promise.resolve();
                                },
                              }),
                            ]}
                          >
                            <Input disabled={disable} />
                          </Form.Item>
                        </Col>
                        <Col>
                          <Form.Item
                            as={Col}
                            className="mb-3"
                            label={t("state")}
                            name={"mailingState"}
                            rules={[
                              {
                                required: true,
                                message: "state is required",
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if(!addressValidated) {
                                    return Promise.reject(
                                      "Please check State Name"
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              }),
                            ]}
                          >
                            <Select
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              disabled={disable}
                            >
                              {cities.map((city, index) => {
                                return (
                                  <Option key={index} value={city.value}>
                                    {city.value}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col>
                          <Form.Item
                            name="mailingZip"
                            label={t("zip")}
                            rules={[
                              {
                                required: true,
                                pattern: patterns.phoneNumberCheckPattern,
                                message: t("value_contain_number"),
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (!addressValidated) {
                                    return Promise.reject(t("zip_error"));
                                  }
                                  return Promise.resolve();
                                },
                              }),
                            ]}
                          >
                            <Input disabled={disable} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <h5>{t("Select_Your_Payment_Type")}:</h5>
                    </Col>
                    <Col>
                      <Form.Item
                        label={t("Reward_Type")}
                        name={"paymentType"}
                        rules={[
                          {
                            required: true,
                            message: t("payment_type_error"),
                          },
                        ]}
                      >
                        <Radio.Group className="d-flex justify-content-around">
                          <Col>
                            <Radio
                              value={rewardTypes.virtual}
                              disabled={virtualVisaDisable || disable}
                            >
                              {t("virtual_visa")}
                              <Image
                                fluid
                                className="radio-images d-block"
                                src="images/PowurVirtualVisa.png"
                              />
                              <p className="fs-6 mt-2">
                                {t("virtual_visa_helper")}
                              </p>
                            </Radio>
                          </Col>
                          <Col>
                            <Radio
                              value={rewardTypes.physical}
                              disabled={disable||phydisable}
                            >
                              {t("physical_visa")}
                              <Image
                                fluid
                                className="radio-images d-block"
                                src="images/PowurVisa.png"
                              />
                            </Radio>
                          </Col>
                          {!check && (
                            <Col>
                              <Radio
                                value={rewardTypes.check}
                                // className={check}
                                disabled={disable||check}
                              >
                                {t("check")}
                                <Image
                                  fluid
                                  className="radio-images d-block"
                                  src="images/PowurCheck.png"
                                />
                              </Radio>
                            </Col>
                          )}
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className="py-5 justify-content-center" hidden={disable}>
                    <Col sm={2}>
                      <Button
                        onClick={verifyHandler}
                        disabled={paymentReemed}
                        className="powur-btn-primary w-100"
                      >
                        {t("continue")}
                      </Button>
                    </Col>
                  </Row>
                  <Row className="py-5 justify-content-center" hidden={hidden}>
                    <Col md={3}>
                      <Button
                        className="powur-btn-primary w-100"
                        onClick={() => {
                          setHidden(true);
                          setDisable(false);
                        }}
                      >
                        {t("back")}
                      </Button>
                    </Col>
                    <Col md={3}>
                      <Button type="submit" className="powur-btn-primary w-100">
                        {t("submit")}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Col>
          </Row>

          <Footer />
        </Container>
      </>
    );
}

export default PaymentsForm;