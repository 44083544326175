export function amountFormat(amount) {
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  if (amount !== null && amount !== undefined) {
    let result;
    if (isNaN(parseFloat(amount))) {
      result = amount;
    } else {
      result = parseFloat(amount).toFixed(2);
    }
    result = result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    if (String(result).includes(".")) {
      if (String(result).includes("$")) {
        return `${result}`;
      } else {
        return `$${result}`;
      }
    } else {
      if (String(result).includes("$")) {
        return `${result}.00`;
      } else {
        return `$${result}.00`;
      }
    }
  } else {
    return "";
  }
}

export const getBpsAccountUrl = (token, type) => {
  const baseUrl = process.env.REACT_APP_MQ_URL
  return `${baseUrl}/create-account?redemptionToken=${token}&type=${type}`
}