import React, {Fragment} from 'react';
import 'antd/dist/antd.min.css';
import { Container, Col, Row } from 'react-bootstrap';
import './Footer.css';
import { useTranslation } from "react-i18next";


const Footer = () => {

  const { t } = useTranslation();


    return (
        <section className='footer'>
           <Container fluid className='p-4 p-md-5'>
                <Row className='justify-content-center px-md-5 pb-2'>
                    <Col>
                        <p>{t("footer_note")}</p>
                    </Col>
                </Row>
                <Row className='justify-content-between'>
                    <Col sm={6}>
                        <ul className='footer-nav text-center text-sm-start'>
                            <li>
                                <a href='https://powur.com/privacy-policy.html' target={'_blank'} className='footer-link me-4'>{t("privacy_policy")}</a>
                            </li>
                            <li>
                                <a href='https://powur-public.s3.us-west-1.amazonaws.com/purchase_terms_and_conditions/Independent-Powur-Seller-Application-v2.0.pdf' target={'_blank'} className='footer-link'>{t("terms_of_service")}</a>
                            </li>
                        </ul>
                    </Col>
                    <Col sm={6}>
                        <p className='text-center text-sm-end'>© Powur {new Date().getFullYear()} </p>
                    </Col>
                </Row>
           </Container>
        </section>
    );
}

export default Footer;