import React, { useState, Fragment, useEffect } from "react";
import styled from 'styled-components';
import queryString from 'query-string'
import { gql, useMutation } from '@apollo/client'
import LoadingOverlay from 'react-loading-overlay'
import axios from 'axios';
import { useTranslation } from "react-i18next";
import Footer from './Footer';
import './Footer.css';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import Image from 'react-bootstrap/Image'
import { getBpsAccountUrl } from "../Helper/helperFunction"

const Styles = styled.div`
.bg-SunPowerBlue {
	background-color: #0076be;
}
.payment-radio {
    margin-left: 150px;
}
.form-check-label {
    text-align: center important!;
}
.ant-radio-button {
    padding: 10px;
}
.OrangeBtn {
    margin-left: 400px;
	font-weight: 500;
	background-color: #F18B21;
	color: hsla(0,0%,100%,1.00);
}
.OrangeBtn:hover {
    
	background-color: #E2620E;
	color: hsla(0,0%,100%,1.00);
}
.BlueBtn {
	font-weight: 500;
	background-color: #0076BE;
	color: #FFFFFF;
}

.BlueBtn:hover {
	background-color:#39607A;
	color: #FFFFFF;
}

.BlueBtn:active {
	background-color:#39607A;
	color: #FFFFFF;
}
.BlueBtn:focus {
	background-color:#39607A;
	color: #FFFFFF;
}
.left-align {
	margin-left: -100px;
}

.BlueBtnOutline {
	font-weight: 500;
	border: 1px solid #0076BE;
    background-color:#FFFFFF !important;
    color: #0076BE;
}

.BlueBtnOutline:hover {
	background-color:#0076BE !important;
	color: #FFFFFF;
}

.BlueBtnOutline:focus {
	background-color:#39607A !important;
	color: #FFFFFF;
}

.BlueBtnOutline:active {
	background-color:#39607A !important;
	color: #FFFFFF;
}

.BlueRadioBtn:hover {
	border: 1px solid #0076BE;
	color: #0076BE;
}

.BlueRadioBtn.active {
	border: 1px solid #0076BE;
	color: #0076BE;
}

main {
	min-height: 100vh;
	padding-bottom: 90px;
}

footer{
	height: 80px;
	margin-top: -80px;
	width: 100%;
}


.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }

.divider-light {
	background-color: #E0E0E5;
	height: 2px;
	margin-bottom: 14px;
}

.divider-orange {
	background-color: #F18B21;
	height: 3.5px;
	width: 36px;
}

.divider-orange-lg {
	background-color: #F18B21;
	height: 3px;
	width: 100px;
}


.bg-banner1 {
	background-image: url("images/1200x627_banner no logo (1).png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: bottom;
}

.bg-SunPowerBlack {
	background-color: hsla(0,0%,0%,0.60);
	color:#FFFFFF;
	text-align: center;
}



/*Nav*/
.nav-link.active {
	color: #F18B21;
	border-bottom: #F18B21 2px solid;
}

.nav-link:hover {
	color: #F18B21;
	border-bottom: #F18B21 2px solid;
}
/*Nav*/

/*Tabs*/

.nav-link.tab-title {
	color: #858585;
	background-color: #EEEEEE;
}

.nav-link.tab-title:hover {
	color: #E2620E;
}

.nav-link.tab-title.active {
	color: #E2620E;
}

/*Tabs*/


/*Links*/

.OrangeLink {
	color: #F18B21;
}
.OrangeLink:hover {
	color: #E2620E;
}

.BlueLink {
	color: #0076BE;
}

/*Typography*/

h1, h2, h3, h4, h5 {
	font-weight: 500;
}
`;

/*const REDEEM_MUTATION = `
    mutation VirtualCardRedeem($token : String!) {
        virtualCardRedeem(token: $token) {
            redeemUrl
            redeemToken
        }
    }
`;*/
const REDEEM_MUTATION = (token) => `
  mutation {
    virtualCardRedeem(token: "${token}") {
        redeemUrl
        redeemToken
        customErrorMessage
    }
}
`
const axiosGraphQL = axios.create({
    baseURL: process.env.REACT_APP_SYSTEM_URL,
});

const RedeemVirtual = (props) => {
    const { t } = useTranslation();
    const values = queryString.parse(window.location.search);
    const token = values.token;
    const [showSpinner, setShowSpinner] = useState(true);
    const [showMessage, setShowMessage] = useState(false);
    const [customMessage, setShowCustomMessage] = useState();
    const mqSiteUrl = process.env.REACT_APP_MQ_URL;
    //const [redeemToken] = useMutation(REDEEM_MUTATION)

    // Commented as now we are redirecting to MQ.
    // useEffect(() => {
    //     /*redeemToken({
    //         variables: { token }
    //         }).then(
    //         res => {
    //             console.log('res: ',res);

    //         },
    //         err => {
    //             console.log(err); 
    //         }
    //         );*/
    //         try {
    //             axiosGraphQL
    //             .post('', {
    //             query: REDEEM_MUTATION(token),
    //             })
    //             .then(result => {
    //                 if (result.data.data.virtualCardRedeem.redeemToken === 'queued') {
    //                     setShowMessage(true);
    //                     setShowSpinner(false);
    //                 } else if(result.data.data.virtualCardRedeem.redeemToken === 'error') {
    //                     setShowCustomMessage(result.data.data.virtualCardRedeem.customErrorMessage);
    //                     setShowSpinner(false);                    
    //                 }
    //                 else {
    //                     window.location = result.data.data.virtualCardRedeem.redeemUrl;
    //                 }
    //             });
    //         } catch (err) {
    //             console.log(err);
    //             console.log('failed');
    //         }
    //   }, []);

    useEffect(() => {
        if (mqSiteUrl) {
            // Redirect to the specified URL if REACT_APP_MQ_URL is present
            setShowSpinner(true)
            window.location.replace(getBpsAccountUrl(token, "virtualvisa"))
        }
    }, [mqSiteUrl, token])

    return (
        <LoadingOverlay
            styles={{
                wrapper: {
                    minHeight: "100%"
                }
            }}
            active={showSpinner}
            spinner
            text="Redirecting to CardAccount site..."
        >
            <Fragment>
                <Container className="vh-fill">

                    <Styles>
                        <Container fluid className='p-4 '>
                            <Row>
                                <Col md={10}>
                                    <Image fluid src='images/Power Logo.png' className='nav-logo' />
                                </Col>

                            </Row>
                        </Container>
                        <Container fluid className="pt-5">
                            <div className="text-center">
                                <div className="row justify-content-center">
                                    <div className="col-10 p-5">
                                        <p style={{ display: ((showMessage) ? 'block' : 'none') }}>{t('request_taking_longer_than_usual')}</p>
                                        <p style={{ display: ((showMessage) ? 'block' : 'none') }}>{t('apologize_inconvenience')}</p>
                                        <p style={{ display: ((customMessage) ? 'block' : 'none') }}>{customMessage}</p>
                                    </div>
                                </div>
                            </div>
                        </Container>

                    </Styles>
                </Container>

                <Footer />
            </Fragment>
        </LoadingOverlay>
    );
}

export default RedeemVirtual;