import React, { Fragment } from "react";
import "antd/dist/antd.min.css";
import { Container, Col, Row, Button, InputGroup } from "react-bootstrap";
import Navigation from "./Navigation";
import TrackingHeader from "./TrackingHeader";
import Footer from "./Footer";
import "./Content.css";
import { useTranslation } from "react-i18next";
import { gql } from "@apollo/client";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Form, Input, Select, message as popUpMessage } from "antd";
const { TextArea } = Input;



const Contact = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const subject = [
    t('Powur_Payment_Inquiry'),
    t('My_Powur_Payment_Status'),
    t("My_Powur_Visa_Reward_Card4"),
    t("My_Powur_Check"),
    t("My_Powur_Virtual_Visa_Card"),
    t("Update_Information_for_my_Powur_Payment"),
    t("Powur_Payment_Email_not_Received"),
    t("Powur_Payment_Expired"),
    t("Powur_Payment_Is_Incorrect_Amount"),
    t("Tracking_Number_Request_for_Powur_Payment"),
  ];

  const onFinish = async (values) => {
    const { subject, First_and_Last_Name, Email_Address, message } = values;
    try {
      const res = await sendContactDetails({
        variables: {
          subject,
          First_and_Last_Name,
          Email_Address,
          message
        },
      });
      if (res.data.contactUs.message == true) {
        popUpMessage.success({
          content: t('Contact_us_successfully_submit'),
          style: {
            marginTop: "10vh",
            fontSize: "1vw",
          },
          duration: 5,
        });
        form.resetFields();
      }
    } catch (error) {
      popUpMessage.error({
        content: "Failed to save",
        style: {
          marginTop: "10vh",
          fontSize: "1vw",
        },
        duration: 5,
      });
    }
  };


  const SENDCONTACTDETAILS = gql`
  mutation contactUs(
      $message:String!
      $subject:String!
      $First_and_Last_Name:String!
      $Email_Address:String!,
  ){
      contactUs(
        input: {
            name: $First_and_Last_Name
            subject:$subject
            message: $message
            email: $Email_Address
        }
      ) {
          message
        }
    }
    `
  const [sendContactDetails] = useMutation(SENDCONTACTDETAILS);



  return (
    <section>
      <Navigation />
      <TrackingHeader />
      <Container className="my-5">
        <Row>
          <Col>
            <h2>{t("E-Customer_Service")}</h2>
            <p>{t("Contact_by_phone_heading")} : 989 - 704 - 7932</p>
          </Col>
        </Row>
        <Row>
          <Col sm={7} className="pe-sm-5 border-end">
            <Form name="contactus" onFinish={onFinish} form={form} autoComplete="off">
              <Form.Item name="subject"
              rules={[
                {
                  required:true,
                  message: t("Please_select_your_subject")
                }]
              }>
                <Select placeholder={t('select_placeHolder')}>
                  {subject.map((sub) => {
                    return <Select.Option value={sub}>{sub}</Select.Option>;
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                name="First_and_Last_Name"
                rules={[
                  {
                    required: true,
                    message: t("Please_input_your_Name"),
                  },
                ]}
              >
                <Input placeholder={t("First_and_Last_Name")} />
              </Form.Item>

              <Form.Item
                name="Email_Address"
                rules={[
                  {
                    required: true,
                    message: t("Please_input_your_Email"),
                  },{
                    type:"email",
                    message:t("Please_enter_valid_email_address")
                  }
                ]}
              >
                <Input placeholder={t("Email_Address")} />
              </Form.Item>

              <Form.Item name="message" 
              rules={[
                {
                  required:true,
                  message:t("Please_enter_your_message")
                },
              ]}>
                <TextArea rows={4} placeholder={t("Your_Message")} />
              </Form.Item>
              <Form.Item>
                <Button type="submit" className='powur-btn-primary'>
                  {t("Submit")}
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col sm={5} className="ps-sm-7 order-1 order-sm-2">
            <h5>{t("Contact_by_phone_heading")}:</h5>
            <p className="mb-3">
              <a href="tel:989-704-7932">989 - 704 - 7932</a>
            </p>
            <h5>{t("Hours_Of_Operation")}</h5>
            <p>
              Mon - Fri | 8:00 AM - 4:00 PM PST
            </p>
            <h5>{t("Cardholder_Services_Contact_Information")}</h5>
            <p>
              <a href="tel:1-844-725-8818">1-844-725-8818</a><br></br>
              <a href="http://bpsmyaccount.com/">bpsmyaccount.com</a> 
            </p>
            <p>
            </p>
          </Col>        
          </Row>
      </Container>
      <Footer />
    </section>
  );
};

export default Contact;
