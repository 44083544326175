import React, { Fragment, useState } from 'react';
import { Nav, Navbar, Container, Image } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import i18next from 'i18next';
import './Navigation.css';
import { Select } from 'antd';
import { useTranslation } from "react-i18next";


const Navigation = () => {
  const { Option } = Select;
  const [selected, setSelected] = useState(localStorage.getItem('i18nextLng'))
  const { t } = useTranslation();

  const handleChange = (value) => {
    localStorage.setItem('i18nextLng', value)
    setSelected(value)
    i18next.changeLanguage(value)
  };



  return (
    <>
      <Navbar expand="lg" bg="white">
        <Container>
          <Navbar.Brand to="/"><Image fluid src='images/Power Logo.png' className='nav-logo' /></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              <NavLink to="/">{t("Track_Payment")}</NavLink>
              <NavLink to="/faq">{t("FAQ")}</NavLink>
              <NavLink to="/contactus">{t("Contact_Us")}</NavLink>
              {/* <a href="https://bpsmyaccount.com/home" target={'_blank'} rel="noreferrer">{t("Card_Balance")}</a> */}
              <NavLink to="/card-balance">{t("Card_Balance")}</NavLink>
              <Navbar.Collapse className='ms-2'>
                <Select
                  onChange={handleChange}
                  defaultValue={selected}
                  style={{width:'150px'}}
                >
                  <Option value="en">English</Option>
                  <Option value="es">Spanish</Option>
                </Select>
              </Navbar.Collapse>
            </Nav>

          </Navbar.Collapse>

        </Container>
      </Navbar>
    </>
  );
}

export default Navigation;